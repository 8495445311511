<template lang="pug">
ui-btn.mb-3(large @click="redirect")
  ui-icon.mr-2(name="sber-logo" color='sber' size='22')
  p Войти по Сбер ID
</template>

<script setup>
const API = useSberIdApi();

// eslint-disable-next-line require-jsdoc, @typescript-eslint/no-unused-vars
const redirect = async () => {
  const { data: response, status, error } = await API.getAuthorizeUrl();

  if (error.value) {
    // TODO: добавить обработку ошибки!
    console.log('status error', status.value);
    console.log('error', error.value);
  } else {
    window.location.href = response.value.url;
  }
};
</script>
