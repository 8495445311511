<template lang="pug">
ui-popup(
  :title="titleText"
  ref="popupRef"
  @submit="request"
  :submit-text="submitText"
  :submit-disable="disableSubmit"
  :declain-text="declainText"
  )
  template(v-if="step === 0")
    ui-input(v-model="name" type='text' large title="Укажите ФИО"  :errors="errors?.name")
    .registration-popup-wrap
      ui-checkbox.mb-3(v-model="userAgreement"  :errors="errors?.user_agreement") 
        | Принимаю 
        a(href="#") пользовательское соглашение
      
      ui-checkbox(v-model="personalDataAgreement" :errors="errors?.personal_data_agreement") 
        | Даю согласие на 
        a(href="#") обработку персональных данных
  template(v-if="step === 1")
    ui-textarea(:text="agreementText" scrollEnd @scrolled="handleTextScrollEnd")
    ui-checkbox.registration-popup-checkbox(v-model="confInfoAgreement" :disabled="disablePolitics"  :errors="errors?.conf_info_agreement") Я ознакомился  соглашением и принимаю его условия
  </template>

<script>
// TODO: переписать на setup!

const NAME_REGEX = /^[a-zа-яё-]+\s[a-zа-яё-]+\s[a-zа-яё-]+$/i;
const API = useAuthApi();

export default {
  props: {
    phone: { type: String, default: '' },
    code: { type: String, default: '' },
  },
  // eslint-disable-next-line require-jsdoc
  data() {
    return {
      name: '',
      confInfoAgreement: false,
      userAgreement: false,
      personalDataAgreement: false,
      disablePolitics: true,
      errors: undefined,
      step: 0,
      agreementText:
        'Настоящий документ представляет собой предложение (публичную оферту) Публичного акционерного общества «Сбербанк России» (далее - ПАО Сбербанк / Банк) заключить соглашение о неразглашении конфиденциальной информации (далее - Соглашение) на изложенных ниже условиях. Заключая настоящее Соглашение, Получающая сторона соглашается со всеми его условиями и гарантирует конфиденциальность любой информации, полученной от ПАО Сбербанк, касающейся проблемных активов Банка/ группы ПАО Сбербанк, а также полученной посредством доступа к интернет - сайту «Портал DA» https://portal-da.ru/ Получающая сторона присоединяется к настоящему Соглашению на странице сайта https://portal-da.ru/ путем нажатия на кнопку «С условиями Соглашения ознакомился и согласен». Нажатие кнопки «С условиями Соглашения ознакомился и согласен» является принятием(акцептом) оферты ПАО Сбербанк, подтверждением ознакомления и согласия со всеми условиями Соглашения, а равно заключением настоящего Соглашения.',
    };
  },

  computed: {
    /**
     * Возвращает текст заголовка на основе текущего шага.
     * @returns {string} Текст заголовка, который зависит от текущего шага.
     */
    titleText() {
      return this.step === 0 ? 'Необходимые формальности' : 'Соглашение о неразглашении конфиденциальной информации';
    },
    /**
     * Возвращает текст для кнопки отправки на основе текущего шага.
     * @returns {string} Текст кнопки отправки, который зависит от текущего шага.
     */
    submitText() {
      return this.step === 0 ? 'Войти' : 'Принять';
    },
    /**
     * Возвращает текст для кнопки отказа на основе текущего шага.
     * @returns {string} Текст кнопки отказа, который зависит от текущего шага.
     */
    declainText() {
      return this.step === 0 ? '' : 'Отказаться';
    },
    /**
     * Проверяет, должна ли быть кнопка отправки отключена на основе текущего шага и валидности имени или политики.
     * @returns {boolean} Возвращает true, если кнопка отправки должна быть отключена, иначе false.
     */
    disableSubmit() {
      return this.step === 0 ? !this.validName : !this.confInfoAgreement;
    },
    /**
     * Проверяет, является ли имя валидным согласно регулярному выражению NAME_REGEX.
     * @returns {boolean} Возвращает true, если имя валидно, иначе false.
     */
    validName() {
      return NAME_REGEX.test(this.name) && this.personalDataAgreement && this.userAgreement;
    },
  },

  // пока оставить
  // eslint-disable-next-line require-jsdoc
  // mounted() {
  //   this.$refs.popupRef.openPopup();
  // },

  methods: {
    // eslint-disable-next-line require-jsdoc
    openPopup() {
      this.$refs.popupRef.openPopup();
    },

    /**
     * Выполняет запрос на сервер в зависимости от текущего шага.
     */
    request() {
      if (this.disableSubmit) return;

      this.errors = undefined;
      this.step === 0 ? this.sendUserData() : this.registerUser();
    },
    /**
     * Отправляет данные пользователя на сервер и переходит на следующий шаг.
     */
    async sendUserData() {
      try {
        const user = {
          name: this.name,
          user_agreement: this.userAgreement,
          personal_data_agreement: this.personalDataAgreement,
        };

        const { status, error } = await API.checkUserData(user);
        console.log('send user data', status, error);

        if (status.value === 'success') {
          this.step++;
        } else {
          this.errors = error.value.data.errors;
        }
      } catch (error) {
        console.log('send user data error', error);
      }
    },

    /**
     * Отправляет данные пользователя на сервер и переходит на следующий шаг.
     */
    async registerUser() {
      try {
        const user = {
          name: this.name,
          phone: this.phone,
          sms_code: this.code,
          user_agreement: this.userAgreement,
          personal_data_agreement: this.personalDataAgreement,
          conf_info_agreement: this.confInfoAgreement,
        };

        const { data: response, status, error } = await API.registerUser(user);

        if (status.value === 'success') {
          const userStore = useUserStore();
          const token = response.value.token;
          const refreshToken = response.value.refresh_token;
          const authRedirectPath = useAuthRedirectPath();
          const redirectPath = authRedirectPath.getPath();
          userStore.signIn(token, refreshToken);

          await navigateTo(redirectPath);
        } else {
          this.errors = error.value.data.errors;
        }
      } catch (error) {
        console.log('registerUser', error);
      }
    },

    /**
     * Обрабатывает завершение прокрутки текста и устанавливает значение disablePolitics в false.
     */
    handleTextScrollEnd() {
      this.disablePolitics = false;
    },
  },
};
</script>

<style lang="scss">
.registration-popup {
  &-wrap {
    margin-top: 44px;
  }
  &-checkbox {
    margin: 22px 0 42px;
  }
}
</style>
