<template lang="pug">
.ui-code-input
  label 
    | Введите код из СМС
    span.ui-code-input__description Код выслан на номер {{ phone }}
  .d-flex.mt-4
    input.otp-input(
      v-for="index in 4"
      :key="index"
      :ref="`otpInput${index}`"
      v-model="otpDigits[index - 1]"
      :class="{'otp-input--disable' : disable, 'otp-input--error': errors.length}"
      type="number"
      maxlength="1"
      @input="handleInput(index)")
  span.ui-input__error-message(v-if="errors.length" v-for="error in errors") {{ error }}
</template>

<script>
export default {
  props: {
    phone: { type: String, default: '' },
    modelValue: { type: String, default: '' },
    disable: { type: Boolean, default: false },
    errors: { type: Array, default: () => [] },
  },
  emits: ['update:modelValue'],
  // eslint-disable-next-line require-jsdoc
  data() {
    return {
      otpDigits: ['', '', '', ''], // массив для хранения 4 цифр OTP
    };
  },

  methods: {
    /**
     * Обрабатывает ввод цифр в поле OTP (One-Time Password).
     * @param {number} index - Индекс вводимой цифры в поле OTP.
     * @returns {void}
     * @throws {Error} Вызывает ошибку, если введенное значение не является цифрой.
     * @description
     * Ограничивает ввод одной цифрой и переходит к следующему полю при вводе.
     * Автоматически отправляет введенный код, если все поля заполнены.
     * @example
     * handleInput(1); // Функция вызывается при вводе первой цифры в поле OTP.
     */
    handleInput(index) {
      // Ограничиваем ввод одной цифрой и переходим к следующему полю при вводе
      if (this.otpDigits[index - 1] && index < 4) {
        this.$refs[`otpInput${index + 1}`][0].focus();
      }

      // авто-запрос, если заполнено
      if (this.otpDigits.join('').length === 4) {
        console.log('автоматически отправляем код: ', this.otpDigits.join(''));
        this.$emit('update:modelValue', this.otpDigits.join(''));
      }
    },
  },
};
</script>

<style lang="scss">
.ui-code-input {
  display: flex;
  flex-direction: column;
  color: $gray100;

  label {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 6px;
  }

  &__description {
    margin-left: 8px;
    color: $gray60;
  }

  & input {
    border: 1px solid $gray40;
    width: 40px;
    height: 56px;
    border-radius: 6px;
    font-size: 38px;
    line-height: 46px;
    text-align: center;
    color: $gray80;
    transition: all 0.3s ease;
    font-family: 'Ysabeau';
    margin-right: 10px;
    outline: none;

    &:focus,
    &:focus-visible {
      color: $gray100;
      border-bottom: 1px solid $gray40;
      outline: none;
    }

    &.otp-input {
      opacity: 1 !important;
      padding: 0;

      &--disable {
        background: $gray10;
        color: $gray60;
        opacity: 1 !important;
        padding: 0;

        &:disabled {
          opacity: 1 !important;
          padding: 0;
        }
      }
      &--error {
        border: 1px solid $red;
      }
    }
  }

  &__error-message {
    color: $red;
    font-size: 14px;
    margin-top: 4px;
  }
}
</style>
