<template lang="pug">
.main-content
  .main-content__wrap
    img.main-content__logo(src="@/assets/images/logo.svg")
    h1.main-content__title Эксклюзивная витрина <br> для клиентов Private
    p.main-content__description Место, где избранные могут найти самые редкие и интересные активы
    template(v-if="sberIdEnabled")
      auth-sber-id
    //- ui-btn(v-if="smsEnabled" transparent large @click="showPopup") Войти по номеру телефона
  
  auth-modal(v-if="smsEnabled" ref="popupRef")
</template>

<script setup>
useHead({
  title: 'Portal DA Private',
});

const config = useRuntimeConfig();
const smsEnabled = config.public.authMethods.smsEnabled;
const sberIdEnabled = config.public.authMethods.sberIdEnabled;
const popupRef = ref(null);
/**
 * Открывает попап со входом по телефону.
 */
const showPopup = () => {
  popupRef.value.openPopup();
};

onMounted(() => {
  document.documentElement.style.background = '#1A1E23';
});
</script>

<style lang="scss">
.main-top-block {
  height: 32px;
  width: 100%;
  background: $gray100;
  color: $gray40;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 20px;
  font-size: 14px;
}
.main-content {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background: $gray80 url('../assets/images/bg-index.webp') 20% bottom / cover no-repeat;
  // background: $gray80 url('../assets/images/bg-index-ny.webp') center center / cover no-repeat;
  padding: 91px 0 100px 94px;
  color: white;

  &__logo {
    width: 296px;
    height: 48px;
    margin-bottom: 75px;
    margin-left: 12px;
  }
  &__title {
    font-size: 64px;
    line-height: 1.1;
    letter-spacing: -0.01em;
    margin-bottom: 17px;
    margin-left: 8px;
    opacity: 0.9;
  }
  &__description {
    font-size: 22px;
    margin-bottom: 98px;
    width: 350px;
    line-height: 1.5;
    letter-spacing: -0.01em;
    margin-left: 10px;
    opacity: 0.9;
  }
}

// MEDIA 320-600
@media screen and (min-width: $mobile-min) and (max-width: $mobile-max) {
  .main-content {
    padding: 80px 24px 32px 24px;
    background: $gray80 url('../assets/images/bg-index.webp') 20% bottom / cover no-repeat;
    // background: $gray80 url('../assets/images/bg-index-ny.webp') 25% center / cover no-repeat;

    &__wrap {
      width: auto;
    }
    &__logo {
      width: 245px;
      height: 40px;
      margin-bottom: 42px;
      margin-left: 5px;
    }
    &__title {
      font-size: 44px;
      margin-bottom: 17px;
      margin-left: 0;
      line-height: 1.2;
    }
    &__description {
      font-size: 20px;
      width: 230px;
      margin-bottom: 114px;
      margin-left: 0;
    }

    & button.ui-btn {
      width: 100%;
    }

    .popup {
      height: 100%;
      border-radius: 0;
      &__footer {
        flex-direction: column;
      }
    }
  }
}

// MEDIA 600-1200
@media screen and (min-width: $tablet-middle-min) and (max-width: $tablet-large-max) {
  .main-content {
    padding: 100px 24px 384px 60px;

    &__logo {
      margin-bottom: 194px;
    }
    &__title {
      line-height: 1.1;
      margin-left: 4px;
    }
    &__description {
      line-height: 1.4;
      letter-spacing: -0.01em;
    }
  }
}

// MEDIA 1200+
@media screen and (min-width: $desktop-large) {
  .main-content {
    padding: 120px 0 361px 194px;
  }
}
</style>
