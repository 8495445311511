<template lang="pug">
.ui-input(:class="{ 'ui-input--large': large }")
  label {{ title }}
  input(
    v-if="type === 'phone'"
    v-maska
    data-maska="+ 7 ### ###-##-##"
    placeholder="+ 7 "
    v-model="inputValue"
    type="text"
    inputmode="tel"
  )
  input(
    v-if="type === 'text'"
    placeholder="Иванов Иван Иванович"
    v-model="inputValue"
  )
  span.ui-input__error-message(v-if="errors.length" v-for="error in errors") {{ error }}
</template>

<script>
export default {
  props: {
    title: { type: String, default: '' },
    modelValue: { type: String, default: '' },
    large: { type: Boolean, default: false },
    errors: { type: Array, default: () => [] },
    type: { type: String, default: '' },
  },
  emits: ['update:modelValue'],

  // eslint-disable-next-line require-jsdoc
  data() {
    return {
      inputValue: this.modelValue,
    };
  },
  watch: {
    /**
     * Обновляет значение входного поля и генерирует событие обновления модели.
     * @param {string} newValue - Новое значение входного поля.
     * @returns {void}
     * @description
     * Если тип поля `phone`, удаляет лишние символы (например, дефисы и пробелы) из нового значения.
     * Затем генерирует событие обновления модели для передачи нового значения.
     * @example
     * inputValue('123-456-7890'); // Функция вызывается при изменении значения входного поля типа 'phone'.
     */
    inputValue(newValue) {
      // убираем лишние символы из инпута
      if (this.type === 'phone') {
        newValue = newValue.replace(/[-\s]/g, '');
      }

      this.$emit('update:modelValue', newValue);
    },
  },
};
</script>

<style lang="scss">
.ui-input {
  display: flex;
  flex-direction: column;
  color: $gray100;
  width: 200px;

  label {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 6px;
  }

  & input {
    border: none;
    border-bottom: 1px solid $gray40;
    font-size: 22px;
    line-height: 29px;
    color: $gray100;
    padding-bottom: 5px;
    transition: all 0.3s ease;
    font-family: 'Ysabeau';

    &:focus,
    &:focus-visible {
      color: $gray100;
      border-bottom: 1px solid $gray60;
      outline: none;
    }
    &.input--empty {
      font-size: 22px;
      color: $gray40;
    }
  }

  &__error-message {
    color: $red;
    font-size: 14px;
    margin-top: 4px;
  }

  &--large {
    width: 100%;

    input {
      font-size: 26px;
      line-height: 34px;
      padding-bottom: 6px;

      &__error-message {
        font-size: 16px;
        margin-top: 2px;
      }
    }
  }
}
</style>
