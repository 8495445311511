<template lang="pug">
auth-login(ref='login' v-if="showLogin" @loginSuccess="handleLoginSuccess" @userInfo="handleUserInfo")
auth-registration(ref='registration' :phone="user?.phone" :code="user?.code")
</template>

<script>
export default {
  // eslint-disable-next-line require-jsdoc
  data() {
    return {
      showLogin: true,
      user: undefined,
    };
  },

  methods: {
    // eslint-disable-next-line require-jsdoc
    openPopup() {
      if (this.showLogin) {
        this.$refs.login.openPopup();
      } else {
        this.$refs.registration.openPopup();
      }
    },
    // eslint-disable-next-line require-jsdoc
    handleUserInfo(user) {
      this.user = user;
    },
    // eslint-disable-next-line require-jsdoc
    handleLoginSuccess(data) {
      if (data === 'need registration') {
        this.showLogin = false;
        this.$refs.registration.openPopup();
      }
    },
  },
};
</script>
