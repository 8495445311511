<template lang="pug">
ui-popup(
  title="Вход"
  ref="popupRef"
  :submit-disable="phone.length !== 12"
  @submit="request"
  :submit-text="step === 0 ? 'Получить код в СМС' : 'Продолжить'"
  )
  template(v-if="step === 1" #left)
    .popup-phone-btns
      .popup-phone-btns__link(@click="step = 0") Изменить номер телефона
      .popup-phone-btns__link(v-if="smsTimer === 0" @click="refreshSmsCode") Выслать код повторно
      .popup-phone-btns__span(v-else) Выслать код повторно через {{ formatTime(smsTimer) }}
      
  template(v-if="step === 0")
    ui-input(v-model="phone" type="phone" large title="Введите номер телефона" :errors="errors?.phone")
  template(v-if="step === 1")
    ui-code-input(v-model="code" :phone="phone" :errors="errors?.sms_code")
</template>

<script setup>
const API = useAuthApi();
const userStore = useUserStore();
const emit = defineEmits(['userInfo', 'loginSuccess']);
const phone = ref('');
const code = ref('');
const name = ref('');
let errors = ref({});
let step = ref(0);
const smsTimer = ref(120);
const popupRef = ref(null);

// пока оставить
// onMounted(() => {
//   popupRef.value.openPopup();
// });

// eslint-disable-next-line @typescript-eslint/no-unused-vars, require-jsdoc
const openPopup = () => {
  popupRef.value.openPopup();
};

// прокидываем наверх функцию
defineExpose({ openPopup });

// eslint-disable-next-line require-jsdoc
const request = () => {
  console.log('step is', step);
  step.value === 0 ? sendPhone() : sendCode();
};

// eslint-disable-next-line require-jsdoc
const sendPhone = async () => {
  errors = {};

  // API
  console.log('phone is ', phone);
  const { status, error } = await API.smsDeliver(phone.value);

  if (status.value === 'success') {
    step.value++;
    // запускаем таймер
    startTimer();
  } else {
    errors.value = error.value.data.errors;
  }
};

// eslint-disable-next-line require-jsdoc
const sendCode = async () => {
  errors = {};

  const { status, error } = await API.smsVerify(phone.value, code.value);

  if (status.value === 'success') {
    userCheckExists();
  } else {
    errors = error.value.data.errors;
  }
};

// eslint-disable-next-line require-jsdoc
const userCheckExists = async () => {
  const { data, status } = await API.userCheckExists(phone.value);

  console.log(data, status.value);

  if (data.value.user_exists) {
    loginUser();
  } else {
    const user = {
      phone: phone.value,
      code: code.value,
    };
    emit('userInfo', user);
    emit('loginSuccess', 'need registration');
  }
};

// eslint-disable-next-line require-jsdoc
const loginUser = async () => {
  const { data, status } = await API.loginUser(phone.value, code.value);

  if (status.value !== 'success') {
    return console.log('close popup and notify');
  }

  const token = data.value.token;
  const refreshToken = data.value.refresh_token;
  const authRedirectPath = useAuthRedirectPath();
  const redirectPath = authRedirectPath.getPath();
  userStore.signIn(token, refreshToken);

  navigateTo(redirectPath);
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars, require-jsdoc
const startTimer = () => {
  const timer = setInterval(() => {
    if (smsTimer.value > 0) {
      smsTimer.value--;
    } else {
      clearInterval(timer);
      // дополнительные действия по завершению таймера здесь
    }
  }, 1000);
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars, require-jsdoc
const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars, require-jsdoc
const refreshSmsCode = () => {
  // API
  smsTimer.value = 120;
  startTimer();
};
</script>

<style lang="scss">
.popup-phone-btns {
  &__link {
    border-bottom: 1px solid $gray20;
    color: $gray80;
    cursor: pointer;
    margin-bottom: 6px;
  }
  &__span {
    color: $gray60;
  }
}
</style>
